$be_primary: #ffe822;
$be_primary_soft: #fff8c0;
$be_primary_text: #000;
$be_primary_button_border: #000;
$be_primary_selection: #f5deb3;

$be_primary_hover_color: #fff;
$be_primary_hover_text: #000;

$be_table_highlightColor: wheat;
$be_table_highlightColorSecondary: #bcf7ff;

$be_primary_active_text: rgb(143, 143, 143);

$be_link_color: black;

$be_toggle_dot_color: #999;

$be_graph_bg_color: #D6CFFD;
$be_graph_toolbox_bg_color: #D6CFFD;

$considered-phone:  576px;

:export {
    consideredPhone: $considered-phone;
    tableHightlightColor: $be_table_highlightColor;
    tableHightlightColorSecondary: $be_table_highlightColorSecondary;
}
/* scroll snap takes care of restoring scroll position */
.sub-app-menu-item-container {
  display: flex;
  overflow: auto;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;  /* Firefox */
}

/* scrollbar should be hidden */
.sub-app-menu-item-container::-webkit-scrollbar {
  display: none; /* Chrome and safari */
}

/* main element should always snap into view */
.sub-app-menu-item-container .menu-item-link {
  scroll-snap-align: start;
}

/* actions and element should be 100% wide */
.sub-app-menu-item-container .menu-item-action,
.sub-app-menu-item-container .menu-item-link {
  min-width: 100%;
}
.sub-app-menu-item-container .menu-item-action {
  display: flex;
  align-items: center;
}
/* icon should remain sticky */
.sub-app-menu-item-container .menu-item-action-left-icon {
  color: white;
  position: sticky;
  left: 16px;
  right: 16px;
}
/* action background colors */
.sub-app-menu-item-container .menu-item-action-left {
  background-color: rgb(150, 150, 150);
}

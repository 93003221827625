@import "styling/variables.scss";

$primary: $be_primary;
$link-color: $be_link_color;
$component-active-color: $be_link_color;
$tertiary: #007bff !default;
$theme-colors: (
  "tertiary": $tertiary,
);
$sizes: (
    10: 10%,
    20: 20%,
    30: 30%,
    40: 40%,
    60: 60%,
    70: 70%,
    80: 80%,
    90: 90%,
    33: calc(100% / 3 * 1),
    66: calc(100% / 3 * 2)
);

@import '~bootstrap/scss/bootstrap';

:root {
    --be-table-hightlight-color: #{$be_table_highlightColor};
    --be-table-hightlight-color-secondary: #{$be_table_highlightColorSecondary};
    --be-table-success-color: #90EE90;
    --be-table-success-secondary-color: #c8f4c8;
    --be-table-error-color: #ee9090;
}

.btn-primary {
    border-color: black;;
}

div.input-group-append .btn-primary {
    border-color: $be_primary;
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #adb5bd;
}

.width-100 {
    width: 100%;
}

.width-1-3 {
    width: calc(100% / 3);
}
.width-1-2 {
    width: calc(100% / 2);
}

.btn-align {
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    vertical-align: middle;
}

.custom-file-label {
    overflow: hidden;
}


.translate-middle {
    transform: translate(-50%, -50%);
}
.translate-middle-x {
    transform: translateX(-50%);
}
.translate-middle-y {
    transform: translateY(-50%);
}

$sizes: 0, 50, 60, 70, 80, 90, 100;
@each $size in $sizes {
    .top-#{$size} {
        top: #{$size + 0%};
    }
    .start-#{$size} {
        left: #{$size + 0%};
    }
    .end-#{$size} {
        right: #{$size + 0%};
    }
}

@each $color, $value in $theme-colors {
    .dropdown-item-#{$color} {
        @include button-variant($value, $value);
    }
}

@each $breakpoint in map-keys($grid-breakpoints) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .no-outer-gutter > .row > .col#{$infix} {
        padding-left: 0;
        padding-right: 0;
    }

    @if $grid-columns > 0 {
        @for $i from 1 through $grid-columns {
            .no-outer-gutter > .row > .col#{$infix}-#{$i} {
                padding-left: 0;
                padding-right: 0;
            }        
        }
    }

    @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
        .no-outer-gutter > .row > .col#{$infix} {
            padding-right: $grid-gutter-width / 2;
            padding-left: $grid-gutter-width / 2;
        }
        .no-outer-gutter > .row > .col#{$infix}:first-child {
            padding-left: 0;
        }
        .no-outer-gutter > .row > .col#{$infix}:last-child {
            padding-right: 0;
        }

        @if $grid-columns > 0 {
            @for $i from 1 through $grid-columns {
                .no-outer-gutter > .row > .col#{$infix}-#{$i} {
                    padding-right: $grid-gutter-width / 2;
                    padding-left: $grid-gutter-width / 2;
                }
                .no-outer-gutter > .row > .col#{$infix}-#{$i}:first-child {
                    padding-left: 0;
                }
                .no-outer-gutter > .row > .col#{$infix}-#{$i}:last-child {
                    padding-right: 0;
                }
            }
        }
    }
}

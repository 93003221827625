body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html, body {
  overscroll-behavior-x: none;
  overscroll-behavior-y: none;
}

.rdg-checkbox-label {
  margin-bottom: 0;
}

.unset-popper-max-width {
  max-width: unset;
}

.react-pdf__Page:not(:last-child) {
  margin-bottom: 0.5em;
}

.react-icons.force-white path {
  stroke: #fff;
}

.focusableHidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

thead.sticky-head tr:nth-child(1) th {
  background: white; 
  position: sticky;
  top: 0;
  z-index: 1;
}

@import "styling/variables.scss";

html, body, #root, .app {
  height: 100%;
}

#root {
  background: white;
}

.container-fluid.striped .row:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.1);
}

.react-icons {
  display: inline-block;
  overflow: visible;
  height: 1.25em;
  width: 1.5em;
  vertical-align: -0.25em;
}

.small-icon {
  height: 0.75em;
  width: 1em;
  vertical-align: 0.0em;
}

.info-tile {
  border-radius: 0.25rem;
  border: 1px solid black;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 0.25rem;
}

.custom-switch.custom-control.centered > label.custom-control-label {
  vertical-align: middle;
  height: 1rem;
}

.no-select {
  user-select: none;
}

.input-left {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.input-right {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.vertical-center {
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
}

.react-datepicker-popper {
  z-index: 20 !important;
}

.language_object:hover {
  background: skyblue;
}

.dot {
  height: 25px;
  width: 25px;
  border-radius: 50%;
}

.red-dot {
  background-color: #e00;
}

.orange-dot {
  background-color: #ff8c00;
}

.green-dot {
  background-color: #0b0;
}

.minh100 {
  min-height: 100%;
}

.flex-container {
  height: 100%;
  display: flex;
  flex-flow: column;
}

.flex-fill-height {
  flex: 1;
  overflow: auto;
}

.flex-limit-fill-height {
  flex: 0 1 auto;
  overflow: auto;
}

.nested-flex-container {
  height: 100%;
  display: flex;
  flex-flow: column;
  flex: 1;
  overflow: auto;
}

.top-margin {
  margin-top: 15px;
}

.app {
  display: flex;
  flex-flow: column;
}

.navbar-brand {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.brandLogo {
  height: 40px;
}

.menu-content-wrapper {
  position: relative;
  flex: 1;
  overflow: auto;
}

.menu {
  float: none;
  position: absolute;
  overflow-x: hidden;
  overflow-y: scroll;
  width: 0px;
  background: $be_primary;
  color: $be_primary_text;
  height: 100%;
  transition: all 0.5s;
  z-index: 998;
}

.content {
  float: none;
  height: 100%;
  position: relative;
  overflow-y: scroll;
  flex: 1;
  padding: 10px;
  transition: all 0.5s;
  width: 100% !important;
}

.menu-content-wrapper.is-open .menu {
  width: 300px;
}

.menu-content-wrapper.is-open .content {
  width: calc(100% - 300px);
}

.menu a {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s;
}

.menu a:hover {
    color: inherit;
}

.menu .menu-open {
  background: $be_primary_soft;
}

.menu .nav-item:hover {
  color: $be_primary_hover_text;
  background: $be_primary_hover_color;
}

.menu a.active-route {
  color: $be_primary_active_text;
}

.menu .items-menu {
  color: $be_primary_text;
  background: $be_primary_soft;
}

.button_large {
  padding: 1.5rem !important;
}

.userMenu {
  z-index: 999 !important;
}

@media only screen and (max-width: $considered-phone) {
  .menu-content-wrapper.is-open .menu {
    width: 100%;
  }
}

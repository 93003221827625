.remote-control-cursor {
    z-index: 99999999999999;
    position: fixed;
    width: 10px;
    height: 10px;
    margin-left: -5px;
    margin-top: -5px;
    background: red;
    border-radius: 50%;
    pointer-events: none;
}
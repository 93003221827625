.page-404 .outer {
    background: #f0f0f0 !important;
    position: absolute;
    top: 0;

    display: table;

    width: 100%;
    height: 100%;
}
.page-404 .outer .middle {
    display: table-cell;
    padding-top: 100px;
}
.page-404 .outer .middle .inner {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
}
.page-404 .outer .middle .inner .inner-circle {
    height: 150px;
    width: 150px;

    border-radius: 50%;
    background-color: #ffffff;
}
.page-404 .outer .middle .inner .inner-circle:hover .icon {
    color: #39bbdb!important;
    background-color: #f5f5f5;
    box-shadow: 0 0 0 15px #39bbdb;
}
.page-404 .outer .middle .inner .inner-circle:hover span {
    color: #39bbdb;
}

.page-404 .outer .middle .inner .inner-circle .icon {
    line-height: 50px;

    float: right;

    width: 75px;
    height: 75px;
    margin-top: -40px;
    margin-right: -40px;
    padding: 20px;

    -webkit-transition: all .4s;
            transition: all .4s;
    text-align: center;

    color: #f5f5f5!important;
    border-radius: 50%;
    background-color: #39bbdb;
    box-shadow: 0 0 0 15px #f0f0f0;
}
.page-404 .outer .middle .inner .inner-circle span {
    font-size: 75px;
    font-weight: 700;
    line-height: 75px;

    display: block;

    -webkit-transition: all .4s;
            transition: all .4s;
    text-align: center;

    color: #e0e0e0;
}
.page-404 .outer .middle .inner .inner-status {
    font-size: 20px;

    display: block;

    margin-top: 20px;
    margin-bottom: 5px;

    text-align: center;

    color: #39bbdb;
}
.page-404 .outer .middle .inner .inner-detail {
    line-height: 1.4em;

    display: block;

    margin-bottom: 10px;

    text-align: center;

    color: #999999;
}
.page-404 .outer .middle .inner .payload {
    line-height: 1.4em;

    display: block;

    margin-bottom: 10px;

    text-align: center;

    color: #999999;
}

.page-404 .outer .middle .inner .payload .payload-contents {
    font-size: 1rem;
    display: inline-block;
    text-align: left;
    color: #4e4e4e;
}


.page-404 .outer .middle .inner .stack-trace {
    line-height: 1.4em;

    display: block;

    margin-bottom: 10px;

    text-align: center;

    color: #999999;
}

.page-404 .outer .middle .inner .stack-trace .stack-trace-contents {
    font-size: 1rem;
    display: inline-block;
    text-align: left;
    color: #4e4e4e;
}
